<template>
  <div class="contact">
    <div
      class="contact-icon-custom border-white bg-dark-green text-center pointer border-dark-green-opacity"
      :class="{ 'bg-green-i': hover }"
      @click="showContact = !showContact"
    >
      <div class="p2">
        <font-awesome-icon
          v-if="!showContact"
          ref="icon"
          icon="comment"
          size="2x"
          class="text-white"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        />
        <font-awesome-icon
          v-else
          ref="icon"
          icon="chevron-circle-down"
          size="2x"
          class="text-white"
          @mouseover="hover = true"
          @mouseleave="hover = false"
        />
      </div>
    </div>
    <el-card
      v-show="showContact"
      class="contact-box"
    >
      <div slot="header">
        <img key="stdmalaga-email" src="../assets/emailus.jpg" />
      </div>
      <div
          v-if="success === null"
          v-loading="loading"
          element-loading-text="Enviando..."
          element-loading-spinner="el-icon-loading"
      >
        <el-form
          ref="form"
          :model="form"
        >
          <el-form-item>
            <el-input v-model="form.name" placeholder="* Nombre" @input="validate('Name')">
              <template slot="prepend">
                <font-awesome-icon
                  ref="icon"
                  icon="user"
                />
              </template>
              <i
                slot="suffix"
                :class="{
                  'el-input__icon el-icon-success text-green-dark': form.validName === true,
                  'el-input__icon el-icon-error text-red': form.validName === false
                }"
              />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.email" placeholder="* Email" @input="validate('Email')">
              <template slot="prepend">
                <font-awesome-icon
                  ref="icon"
                  icon="envelope"
                />
              </template>
              <i
                slot="suffix"
                :class="{
                  'el-input__icon el-icon-success text-green-dark': form.validEmail === true,
                  'el-input__icon el-icon-error text-red': form.validEmail === false
                }"
              />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.phone" placeholder="* Teléfono" @input="validate('Phone')">
              <template slot="prepend">
                <font-awesome-icon
                  ref="icon"
                  icon="phone"
                />
              </template>
              <i
                slot="suffix"
                :class="{
                  'el-input__icon el-icon-success text-green-dark': form.validPhone === true,
                  'el-input__icon el-icon-error text-red': form.validPhone === false
                }"
              />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.company" placeholder="Empresa" @input="validate('Company')">
              <template slot="prepend">
                <font-awesome-icon
                  ref="icon"
                  icon="building"
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="textarea" v-model="form.text" rows="4" placeholder="* Mensaje" @input="validate('Text')">
              <i
                slot="suffix"
                :class="{
                  'el-input__icon el-icon-success text-green-dark': form.validText === true,
                  'el-input__icon el-icon-error text-red': form.validText === false
                }"
              />
            </el-input>
          </el-form-item>
        </el-form>
        <el-button
          class="w100 bg-silver text-silver-dark"
          :disabled="!form.validForm"
          @click="sendMessage"
        >
          <span>Enviar mensaje</span>
        </el-button>
      </div>
      <div
        v-if="success === true"
        class="form-message"
      >
      <div class="w100 text-center">
        <span>
          Su mensaje ha sido enviado con exito, nos pondremos en contacto con usteed lo antes posible.
        </span>
      </div>
      <div class="w100">
        <el-button
          class="w100 bg-silver text-silver-dark mt4"
          @click="success = null"
        >
          <span>Enviar otro mensaje</span>
        </el-button>
      </div>
      </div>
      <div
        v-if="success === false"
        class="form-message"
      >
        <div class="w100 text-center">
          <span>
            {{errorMessage}}
          </span>
        </div>
        <div class="w100">
          <el-button
            class="w100 bg-silver text-silver-dark mt4"
            @click="success = null"
          >
            <span>Intentar de nuevo</span>
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';

const emailRegex = new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export default {
  name: 'Contact',
  data () {
    return {
      hover: false,
      showContact: false,
      form: {
        name: '',
        email: '',
        phone: '',
        company: '',
        text: '',
        validName: null,
        validEmail: null,
        validPhone: null,
        validCompany: null,
        validText: null,
        validForm: false
      },
      success: null,
      loading: false,
      errorMessage: ''
    };
  },
  methods: {
    validate (field) {
      const value = this.form[field.toLowerCase()];
      if (value && value !== '' && value.trim() !== '') {
        if (field === 'Email') {
          const validEmail = emailRegex.test(value);
          this.form['validEmail'] = validEmail;
        } else if (field === 'Phone') {
          const validPhone = value && value.length > 7 && Number.isInteger(Number(value));
          this.form['validPhone'] = validPhone;
        } else {
          this.form[`valid${field}`] = true;
        }
      } else {
        this.form[`valid${field}`] = false;
      }

      this.form.validForm = (this.form.validName === true &&
        this.form.validEmail === true &&
        this.form.validPhone === true &&
        this.form.validText === true) || false;
    },
    async sendMessage () {
      this.loading = true;

      try {
        const result = await axios.post('/.netlify/functions/contact', {
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          company: this.form.company,
          text: this.form.text
        });
        const status = result.status;
        if (status === 202) {
          this.success = true;
          this.loading = false;
        } else {
          throw new Error();
        }
      } catch (error) {
        this.success = false;
        this.loading = false;
        if (error && error.message && error.message.includes('429')) {
          this.errorMessage = 'Ha alcanzado el número máximo de intentos. Espere y vuelva a intentarlo.';
        } else {
          this.errorMessage = 'Lo sentimos, ha habido un error, inténtelo de nuevo o pruebe a llamarnos al 615430188 y estaremos encantaados de atenderle.';
        }
      }
    }
  },
  watch: {
    showContact () {
      this.form = {
        name: '',
        email: '',
        phone: '',
        company: '',
        text: '',
        validName: null,
        validEmail: null,
        validPhone: null,
        validCompany: null,
        validText: null,
        validForm: false
      };
      this.success = null;
      this.loading = false;
    }
  }
};

</script>

<style>

  .contact-icon-custom {
    position: fixed;
    z-index: 100;
    bottom: 25px;
    right: 25px;
    border: 5px solid;
    border-radius: 50%;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .contact-box {
    background-color: white;
    position: fixed;
    z-index: 100;
    width: 400px;
    bottom: 105px;
    right: 25px;
  }

  .el-card {
    border: 0 !important;
  }

  .el-card__header {
    padding: 0 !important;
  }

  .el-input-group__prepend {
    padding: 0 10px !important;
  }

  .el-textarea {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  }

  .el-button:not(.is-disabled):hover {
    opacity: 0.9 !important;
    background-color: #DDDDDD !important;
    border-color: #27406D !important;
    color: #27406D !important;
  }

  .el-button.is-loading, .el-button.is-disabled {
    background-color: #DDDDDD !important;
    opacity: 0.5;
    color: #777777 !important;
  }

  .el-loading-spinner .el-icon-loading, .el-loading-spinner .el-loading-text {
    color: #89BD49 !important;
  }

  .form-message {
    height: 406px;
    padding-top: 35%;
  }

  @media only screen and (min-width: 0px) and (max-width: 550px){
    .contact-icon-custom  {
      bottom: 10px;
      right: 5px;
    }

    .contact-box {
      bottom: 80px;
      right: 5px;
    }
  }

</style>
