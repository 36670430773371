var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"contact-icon-custom border-white bg-dark-green text-center pointer border-dark-green-opacity",class:{ 'bg-green-i': _vm.hover },on:{"click":function($event){_vm.showContact = !_vm.showContact}}},[_c('div',{staticClass:"p2"},[(!_vm.showContact)?_c('font-awesome-icon',{ref:"icon",staticClass:"text-white",attrs:{"icon":"comment","size":"2x"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}}):_c('font-awesome-icon',{ref:"icon",staticClass:"text-white",attrs:{"icon":"chevron-circle-down","size":"2x"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}})],1)]),_c('el-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContact),expression:"showContact"}],staticClass:"contact-box"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('img',{key:"stdmalaga-email",attrs:{"src":require("../assets/emailus.jpg")}})]),(_vm.success === null)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"Enviando...","element-loading-spinner":"el-icon-loading"}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"* Nombre"},on:{"input":function($event){return _vm.validate('Name')}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('template',{slot:"prepend"},[_c('font-awesome-icon',{ref:"icon",attrs:{"icon":"user"}})],1),_c('i',{class:{
                'el-input__icon el-icon-success text-green-dark': _vm.form.validName === true,
                'el-input__icon el-icon-error text-red': _vm.form.validName === false
              },attrs:{"slot":"suffix"},slot:"suffix"})],2)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"* Email"},on:{"input":function($event){return _vm.validate('Email')}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('template',{slot:"prepend"},[_c('font-awesome-icon',{ref:"icon",attrs:{"icon":"envelope"}})],1),_c('i',{class:{
                'el-input__icon el-icon-success text-green-dark': _vm.form.validEmail === true,
                'el-input__icon el-icon-error text-red': _vm.form.validEmail === false
              },attrs:{"slot":"suffix"},slot:"suffix"})],2)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"* Teléfono"},on:{"input":function($event){return _vm.validate('Phone')}},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},[_c('template',{slot:"prepend"},[_c('font-awesome-icon',{ref:"icon",attrs:{"icon":"phone"}})],1),_c('i',{class:{
                'el-input__icon el-icon-success text-green-dark': _vm.form.validPhone === true,
                'el-input__icon el-icon-error text-red': _vm.form.validPhone === false
              },attrs:{"slot":"suffix"},slot:"suffix"})],2)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"Empresa"},on:{"input":function($event){return _vm.validate('Company')}},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}},[_c('template',{slot:"prepend"},[_c('font-awesome-icon',{ref:"icon",attrs:{"icon":"building"}})],1)],2)],1),_c('el-form-item',[_c('el-input',{attrs:{"type":"textarea","rows":"4","placeholder":"* Mensaje"},on:{"input":function($event){return _vm.validate('Text')}},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}},[_c('i',{class:{
                'el-input__icon el-icon-success text-green-dark': _vm.form.validText === true,
                'el-input__icon el-icon-error text-red': _vm.form.validText === false
              },attrs:{"slot":"suffix"},slot:"suffix"})])],1)],1),_c('el-button',{staticClass:"w100 bg-silver text-silver-dark",attrs:{"disabled":!_vm.form.validForm},on:{"click":_vm.sendMessage}},[_c('span',[_vm._v("Enviar mensaje")])])],1):_vm._e(),(_vm.success === true)?_c('div',{staticClass:"form-message"},[_c('div',{staticClass:"w100 text-center"},[_c('span',[_vm._v(" Su mensaje ha sido enviado con exito, nos pondremos en contacto con usteed lo antes posible. ")])]),_c('div',{staticClass:"w100"},[_c('el-button',{staticClass:"w100 bg-silver text-silver-dark mt4",on:{"click":function($event){_vm.success = null}}},[_c('span',[_vm._v("Enviar otro mensaje")])])],1)]):_vm._e(),(_vm.success === false)?_c('div',{staticClass:"form-message"},[_c('div',{staticClass:"w100 text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]),_c('div',{staticClass:"w100"},[_c('el-button',{staticClass:"w100 bg-silver text-silver-dark mt4",on:{"click":function($event){_vm.success = null}}},[_c('span',[_vm._v("Intentar de nuevo")])])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }