<template>
  <div id="app">
    <site-header />
    <slide />
    <site-content />
    <site-footer />
    <contact />
  </div>
</template>

<script>

import SiteHeader from './components/Header.vue';
import Slide from './components/Slide.vue';
import SiteContent from './components/Content.vue';
import SiteFooter from './components/Footer.vue';
import Contact from './components/Contact.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    Slide,
    SiteContent,
    SiteFooter,
    Contact
  },
  data () {
    return {
    };
  }
};

</script>
