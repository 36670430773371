import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './sass/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPhone,
  faEnvelope,
  faClock,
  faCopyright,
  faChevronCircleRight,
  faChevronCircleDown,
  faChevronDown,
  faComment,
  faUser,
  faBuilding
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPhone);
library.add(faEnvelope);
library.add(faClock);
library.add(faCopyright);
library.add(faChevronCircleRight);
library.add(faChevronCircleDown);
library.add(faChevronDown);
library.add(faComment);
library.add(faUser);
library.add(faBuilding);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.component('font-awesome-icon', FontAwesomeIcon);

window.Vue = Vue;

new Vue({
  render: h => h(App)
}).$mount('#app');
