<template>
  <div class="w-100 bg-blue">
    <div class="footer box text-white">
      <div class="w-column">
        <div class="footer-flex">
          <div class="responsive-row">
            <font-awesome-icon icon="copyright" class="text-green mr1"/>
            <span class="hide mobile-show">2015 S.T.D. Málaga</span>
            <span class="mobile-hide">2015 Servicio Técnico Dental Málaga</span>
            <span class="ml1">-</span>
            <span class="ml1">Aviso Legal</span>
          </div>
          <div class="ml-auto responsive-row">
            <span>Desarrollo:</span>
            <!-- <a href="http://www.franciscosancho.com" class="ml1 pointer" target="_blank">Francisco Sancho</a> -->
            <span class="ml1">Francisco Sancho</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data () {
    return {
    };
  }
};

</script>

<style>

.footer {
  height: 45px;
  line-height: 45px;
}

@media only screen and (min-width: 701px) {
  .footer-flex {
    display: flex;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1000px){
  .w-column {
    width: 90%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 700px){
  .footer {
    height: 100px;
  }

  .responsive-row {
    width: 100%;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: inline !important;
  }
}

</style>
