<template>
  <div>
    <div class="bg-blue box">
      <div class="w-column content">
        <div class="text-bold text-xl text-green">Servicio técnico dental personalizado en la provincia de Málaga</div>
        <div class="text-white mt2">
          Empresa con más de 20 años de experiencia en el sector, el mejor precio final y asistencia profesional personalizada a su disposición.
          <br>
          Trabajamos con desplazamiento a clínica o laboratorio en Málaga y provincia, otras provincias sujeto a disponibilidad.
          Resto de la península por mediación de empresa de transporte para aparatología pequeña y ventas.
          Trabajamos todas las marcas del mercado con la mayor profesionalidad, fiabilidad y garantía.
          <br>
          Rellene nuestro formulario y nos pondremos en contacto con usted sin ningún compromiso lo antes posible.
        </div>
      </div>
    </div>

    <div class="bg-white box">
      <div class="w-column content flex">
        <div class="w40 content-images">
          <img src="../assets/gallery/tools.jpg" width="250px"/>
          <img src="../assets/gallery/tools2.jpg" width="250px"/>
        </div>
        <div class="ml6 content-margin-left">
          <div class="text-bold text-xl text-blue">Reparación y mantenimiento</div>
          <div class="text-silver-dark mt2">
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>Reparamos turbinas, contraangulos, micromotores eléctricos y neumáticos etc.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>Trabajamos todas las marcas con repuestos originales,
            contamos con un taller dotado del mejor equipo para un calibrado y reparación profesionales.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>
            Desplazamiento a clínica o laboratorio en la provincia de Málaga (resto de Andalucía sujeto a disponibilidad), envío por mensajería o servicio logístico.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/> No sólo vendemos los mejores productos y reparamos todo tipo de
            aparatología sino que también informamos a nuestros clientes de como mantener su maquinaria para que esta funcione correctamente el mayor tiempo posible.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/><span class="text-bold">Su satisfacción, nuestro compromiso.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-silver box">
      <div class="w-column content flex">
        <div class="mr6">
          <div class="text-bold text-xl text-blue">Venta de material y sillones</div>
          <div class="text-silver-dark mt2">
            <font-awesome-icon icon="chevron-circle-right" class="text-blue mr1"/>Trabajamos con las mejores marcas y disponemos de los mejores precios del mercado,
            fiabilidad, durabilidad y experiencia en el sector del servicio técnico dental son nuestros
            mejores avales.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-blue mr1"/>Disponemos de turbinas, contrángulos, acoplamientos, leds, ultrasonidos,
            compresores, depositos, micromotores, lámparas, taburetes,
            sillones etc.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-blue mr1"/>No dude en contactar con nosotros y le proporcionaremos toda la información
            que requiera con el mejor trato personalizado sin ningún compromiso.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-blue mr1"/>Montamos sillones dentales de la marca FEDESA.
            Contamos con más de veinte años de experiencia con esta empresa sin interrupción.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-blue mr1"/><span class="text-bold">Pídanos presupuesto sin compromiso.</span>
          </div>
        </div>
        <div class="w40 content-images">
          <img src="../assets/gallery/sillon.jpg" width="250px"/>
          <img src="../assets/gallery/sillon2.jpg" width="250px"/>
        </div>
      </div>
    </div>

    <div class="bg-green box">
      <div class="w-column content">
        <div class="text-bold text-xl text-blue">Instalación de Clínicas</div>
        <div class="text-blue">
          Elíjanos para la instalación de su clínica dental, años de experiencia nos avalan.
          <br>
          No dude en pedir presupuesto sin ningún compromiso y estudiaremos que es lo que mejor se
          adapta a sus necesidades.
          <br>
          <span class="text-bold">Acabados profesionales.</span>
          <div class="w100 mt2 content-images">
            <img src="../assets/gallery/clinica.jpg" width="250px"/>
            <img src="../assets/gallery/clinica2.jpg" width="250px" class="ml2"/>
            <img src="../assets/gallery/clinica3.jpg" width="250px" class="ml2"/>
            <img src="../assets/gallery/clinica4.jpg" width="250px" class="ml2"/>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white box">
      <div class="w-column content flex">
        <div class="w40 content-images">
          <img src="../assets/gallery/delivery.jpg" width="250px"/>
          <img src="../assets/gallery/parcel.jpg" width="250px"/>
        </div>
        <div class="ml6 content-margin-left">
          <div class="text-bold text-xl text-blue">Envío por mensajería</div>
          <div class="text-silver-dark mt2">
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>No sólo entregamos a clínicas de Málaga y alrededores, también envíamos por
            paquetería al resto de la península.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>Agilizamos y reducimos gastos innecesarios aprovechando el envío de
            piezas pequeñas por paquetería segura.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/>Envíe su instrumental rotatorio o aparato a nuestra dirección y se lo reenviaremos
            reparado corriendo nosotros con los gastos de envío a través de nuestra empresa de confianza.
            <br>
            <font-awesome-icon icon="chevron-circle-right" class="text-green mr1"/><span class="text-bold">Contacte con nosotros y le atenderemos de manera
              personalizada.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Content',
  data () {
    return {
    };
  }
};

</script>

<style>

.content {
  padding: 35px 0 35px 0;
  line-height: 1.75;
}

@media only screen and (min-width: 701px) and (max-width: 1000px){
  .w-column {
    width: 90%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 700px){
  .w-column {
    width: 90%;
  }

  .content-images {
    display: none;
  }

  .content-margin-left {
    margin-left: 0 !important;
  }
}

</style>
