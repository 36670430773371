<template>
  <div class="w100 bg-blue">
    <div class="header__top box text-white">
      <div class="w-column box">
        <div class="f_row box">
          <div>
            <font-awesome-icon icon="phone" class="text-green mr1" />
            <a href="tel:+34615430188">
              <span class="mobile-hide">615430188</span>
              <span class="hide mobile-show mobile-m">Llámanos</span>
            </a>
            <font-awesome-icon icon="envelope" class="text-green mr1 ml4" />
            <a href="mailto:s.t.d.malaga1@gmail.com">
              <span class="mobile-hide">s.t.d.malaga1@gmail.com</span>
              <span class="hide mobile-show">Email</span>
            </a>
          </div>
          <div class="ml-auto flex">
            <div>
              <font-awesome-icon icon="clock" class="text-green mr1 ml4"/>
              <span class="mobile-hide">Horario de atención:</span>
              <span>08:00 - 21:00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header__main box bg-white">
      <div class="w-column flex mobile-box">
        <div>
          <img src="../assets/stdmalaga_logo.jpg" width="300px"/>
        </div>
        <div class="ml-auto text-bold flex mobile-hide">
          <div class="box">
            <div>
              <span class="w100 text-green text-xl">Consultanos sin Compromiso</span>
              <div class="text-blue text-l mt1">
                <span><a href="tel:+34615430188">615430188</a></span>
                <span> o </span>
                <span class="pointer underline">Déjanos tu teléfono</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data () {
    return {
      width: window.innerWidth
    };
  },
  methods: {
  },
  watch: {
  }
};

</script>

<style>

.header__top {
  height: 45px;
  line-height: 45px;
}

.header__main {
  height: 150px;
}

@media only screen and (min-width: 1001px){
  .w-column {
    width: 70%;
  }

  .f_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1000px){
  .w-column {
    width: 90%;
  }

  .f_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
  }
}

@media only screen and (min-width: 550px) and (max-width: 700px){
  .w-column {
    width: 90%;
  }

  .header__top {
    line-height: 35px;
  }

  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: inherit !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 550px){
  .mobile-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
