<template>
  <div
    class="slide box"
    style="height: 400px"
  >
    <div
      v-for="(n, i) in 4"
      :key="`image${n}`"
      v-show="width > 550 && i === order"
      class="slide-image"
      :style="`background: url(${images[i]})`"
    />
    <div
      v-for="(ns, i) in 4"
      :key="`smallImage${ns}`"
      v-show="width <= 550 && i === order"
      class="slide-image"
      :style="`background: url(${smallImages[i]})`"
    />
    <div class="slide-mask"/>
    <div class="slide-text text-bold text-center contained">
      <div>
        <span class="text-white text-xxl">
          {{texts[order]}}
        </span>
      </div>
      <div>
        <span class="text-silver text-xl">
          {{secondaryTexts[order]}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

const slide1s = require('../assets/slide/slide1-s.jpg');
const slide2s = require('../assets/slide/slide2-s.jpg');
const slide3s = require('../assets/slide/slide3-s.jpg');
const slide4s = require('../assets/slide/slide4-s.jpg');
const slide1 = require('../assets/slide/slide1.jpg');
const slide2 = require('../assets/slide/slide2.jpg');
const slide3 = require('../assets/slide/slide3.jpg');
const slide4 = require('../assets/slide/slide4.jpg');

export default {
  name: 'Slide',
  data () {
    return {
      smallImages: [
        slide1s,
        slide2s,
        slide3s,
        slide4s
      ],
      images: [
        slide1,
        slide2,
        slide3,
        slide4
      ],
      texts: [
        'Reparación y venta',
        'Montamos tu clínica',
        'Contáctanos',
        'Más de 20 años de experiencia'
      ],
      secondaryTexts: [
        'Más de 20 años de experiencia',
        'Acabados profesionales',
        'Te atenderemos sin compromiso',
        'El mejor trato personalizado'
      ],
      order: this.randomNumber(0, 3),
      interval: undefined,
      intervalTime: 5000,
      width: window.innerWidth
    };
  },
  methods: {
    randomNumber (min, max) {
      return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
    },
    initiateSlide () {
      this.interval = setInterval(() => this.nextImage(), this.intervalTime);
    },
    nextImage () {
      this.order++;

      if (this.order > this.images.length - 1) {
        this.order = 0;
      }
    }
  },
  mounted () {
    this.initiateSlide();
  }
};

</script>

<style>

.slide-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.45) 100%);
}

.slide-mask {
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.45) 100%);
  width: 100%;
  top: 195px;
  height: 400px;
  z-index: 2;
}

.slide-text {
  position: absolute;
  margin-top: -25px;
  -webkit-margin-before: -18px;
  z-index: 3;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.2);
}

.slide-text-secondary {
  position: absolute;
  margin-top: 25px;
  -webkit-margin-before: 18px;
  z-index: 3;
  text-shadow: 0 1px 2px rgba(0,0,0,0.2), 0 5px 10px rgba(0,0,0,0.2);
}

.opacity-light {
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.45) 100%);
}

@media only screen and (max-width: 700px){

}

@media only screen and (min-width: 0px) and (max-width: 550px){
  .contained {
    max-width: 80%;
  }
}

</style>
